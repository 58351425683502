/**
 * @generated SignedSource<<594acf6b83df1edc29a5417a2585790f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ChainIdentifier = "AMOY" | "APE_CHAIN" | "APE_CURTIS" | "ARBITRUM" | "ARBITRUM_GOERLI" | "ARBITRUM_NOVA" | "ARBITRUM_SEPOLIA" | "AVALANCHE" | "AVALANCHE_FUJI" | "B3" | "B3_SEPOLIA" | "BAOBAB" | "BASE" | "BASE_GOERLI" | "BASE_SEPOLIA" | "BERA_CHAIN" | "BLAST" | "BLAST_SEPOLIA" | "BSC" | "BSC_TESTNET" | "ETHEREUM" | "FLOW" | "FLOW_TESTNET" | "GOERLI" | "GUNZILLA_TESTNET" | "KLAYTN" | "MATIC" | "MONAD_TESTNET" | "MUMBAI" | "OPTIMISM" | "OPTIMISM_GOERLI" | "OPTIMISM_SEPOLIA" | "RINKEBY" | "RONIN" | "SAIGON_TESTNET" | "SEI" | "SEI_DEVNET" | "SEI_TESTNET" | "SEPOLIA" | "SHAPE" | "SOLANA" | "SOLDEV" | "SONEIUM" | "SONEIUM_MINATO" | "UNICHAIN" | "ZORA" | "ZORA_SEPOLIA" | "ZORA_TESTNET" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type ItemCard_data$data = {
  readonly __typename: string;
  readonly bundleCollection?: {
    readonly slug: string;
    readonly " $fragmentSpreads": FragmentRefs<"CollectionTrackingContext_collection">;
  } | null;
  readonly chain: {
    readonly identifier: ChainIdentifier;
  };
  readonly collection?: {
    readonly slug: string;
    readonly " $fragmentSpreads": FragmentRefs<"CollectionTrackingContext_collection">;
  };
  readonly isDelisted?: boolean;
  readonly orderData: {
    readonly bestAskV2: {
      readonly perUnitPriceType: {
        readonly usd: string;
      };
    } | null;
  };
  readonly relayId: string;
  readonly saleOrListingCancellationOccurred: boolean | null;
  readonly totalQuantity?: string;
  readonly " $fragmentSpreads": FragmentRefs<"ItemCardContent" | "ItemCardCta_item" | "ItemCardFooter" | "ItemTrackingContext_item" | "itemEvents_data" | "item_url">;
  readonly " $fragmentType": "ItemCard_data";
};
export type ItemCard_data$key = {
  readonly " $data"?: ItemCard_data$data;
  readonly " $fragmentSpreads": FragmentRefs<"ItemCard_data">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "relayId",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "concreteType": "ChainType",
  "kind": "LinkedField",
  "name": "chain",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "identifier",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "slug",
  "storageKey": null
},
v4 = [
  (v3/*: any*/),
  {
    "args": null,
    "kind": "FragmentSpread",
    "name": "CollectionTrackingContext_collection"
  }
],
v5 = {
  "alias": null,
  "args": null,
  "concreteType": "AssetContractType",
  "kind": "LinkedField",
  "name": "assetContract",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "address",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "tokenId",
  "storageKey": null
},
v7 = {
  "kind": "Variable",
  "name": "identity",
  "variableName": "identity"
},
v8 = {
  "kind": "Variable",
  "name": "showContextMenu",
  "variableName": "showContextMenu"
};
return {
  "argumentDefinitions": [
    {
      "defaultValue": {},
      "kind": "LocalArgument",
      "name": "identity"
    },
    {
      "defaultValue": false,
      "kind": "LocalArgument",
      "name": "shouldShowBestBid"
    },
    {
      "defaultValue": false,
      "kind": "LocalArgument",
      "name": "showBestAskForOwner"
    },
    {
      "defaultValue": false,
      "kind": "LocalArgument",
      "name": "showContextMenu"
    },
    {
      "defaultValue": false,
      "kind": "LocalArgument",
      "name": "showQuantity"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "ItemCard_data",
  "selections": [
    (v0/*: any*/),
    (v1/*: any*/),
    (v2/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "ESOrderDataType",
      "kind": "LinkedField",
      "name": "orderData",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "OrderV2Type",
          "kind": "LinkedField",
          "name": "bestAskV2",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "PriceType",
              "kind": "LinkedField",
              "name": "perUnitPriceType",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "usd",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "isDelisted",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "totalQuantity",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "CollectionType",
          "kind": "LinkedField",
          "name": "collection",
          "plural": false,
          "selections": (v4/*: any*/),
          "storageKey": null
        },
        {
          "kind": "InlineDataFragmentSpread",
          "name": "itemEvents_data",
          "selections": [
            (v1/*: any*/),
            (v5/*: any*/),
            (v6/*: any*/),
            (v2/*: any*/)
          ],
          "args": null,
          "argumentDefinitions": []
        }
      ],
      "type": "AssetType",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "alias": "bundleCollection",
          "args": null,
          "concreteType": "CollectionType",
          "kind": "LinkedField",
          "name": "collection",
          "plural": false,
          "selections": (v4/*: any*/),
          "storageKey": null
        }
      ],
      "type": "AssetBundleType",
      "abstractKey": null
    },
    {
      "args": [
        (v7/*: any*/),
        {
          "kind": "Variable",
          "name": "showQuantity",
          "variableName": "showQuantity"
        }
      ],
      "kind": "FragmentSpread",
      "name": "ItemCardContent"
    },
    {
      "args": [
        (v7/*: any*/),
        {
          "kind": "Variable",
          "name": "shouldShowBestBid",
          "variableName": "shouldShowBestBid"
        },
        {
          "kind": "Variable",
          "name": "showBestAskForOwner",
          "variableName": "showBestAskForOwner"
        },
        (v8/*: any*/)
      ],
      "kind": "FragmentSpread",
      "name": "ItemCardFooter"
    },
    {
      "args": [
        (v7/*: any*/),
        (v8/*: any*/)
      ],
      "kind": "FragmentSpread",
      "name": "ItemCardCta_item"
    },
    {
      "kind": "InlineDataFragmentSpread",
      "name": "item_url",
      "selections": [
        (v0/*: any*/),
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "kind": "InlineDataFragmentSpread",
              "name": "asset_url",
              "selections": [
                (v5/*: any*/),
                (v6/*: any*/),
                (v2/*: any*/)
              ],
              "args": null,
              "argumentDefinitions": []
            }
          ],
          "type": "AssetType",
          "abstractKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "kind": "InlineDataFragmentSpread",
              "name": "bundle_url",
              "selections": [
                (v3/*: any*/),
                (v2/*: any*/)
              ],
              "args": null,
              "argumentDefinitions": []
            }
          ],
          "type": "AssetBundleType",
          "abstractKey": null
        }
      ],
      "args": null,
      "argumentDefinitions": []
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ItemTrackingContext_item"
    },
    {
      "kind": "ClientExtension",
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "saleOrListingCancellationOccurred",
          "storageKey": null
        }
      ]
    }
  ],
  "type": "ItemType",
  "abstractKey": "__isItemType"
};
})();

(node as any).hash = "d35893f7506622b073700d0566991e21";

export default node;
